import { useState, useEffect } from 'react';
import './App.css';

const lovingWordsArr = [
  "I love the way you support me in all things I do!",
  "I love you!",
  "I love the way you make me smile!",
  "I love how you care for me!",
  "I love the way you want to share your day with me!"
]

function App() {
  const [randomIndex, setRandomIndex] = useState(Math.floor(Math.random() * lovingWordsArr.length));
  const [lovingWord, setLovingWord] = useState(lovingWordsArr[randomIndex]);
  useEffect(() => {
    setLovingWord(lovingWordsArr[randomIndex]);
  }, [randomIndex]);
  console.log(randomIndex);

  return (
    <div className='App'>
      <div className='Header'><h1>I Love You</h1></div>
      <div className='Body'>{lovingWord}</div>
      <button onClick={() => setRandomIndex(Math.floor(Math.random() * lovingWordsArr.length))}>Get loving words</button>
    </div>
  );
}


export default App;
